<template>
  <div class="d-flex-column mw-md main">
    <el-card>
      <h2 id="checkout_total" class="mb-2">Total: {{ formatMoney(checkoutTotal) }}</h2>
      <div class="card-container mb-2"></div>
      <el-form ref="form">
        <div class="mw-500 d-flex-column">
          <SuperInput
            :field="cardForm.number"
            v-model="cardForm.number.value"
            :errors.sync="cardForm.number.errors"
          />
          <SuperInput
            :field="cardForm.name"
            v-model="cardForm.name.value"
            :errors.sync="cardForm.name.errors"
          />
          <div class="d-flex">
            <div class="flex-1">
              <SuperInput
                :field="cardForm.expiry"
                v-model="cardForm.expiry.value"
                :errors.sync="cardForm.expiry.errors"
              />
            </div>
            <div class="flex-1">
              <SuperInput
                :field="cardForm.cvc"
                v-model="cardForm.cvc.value"
                :errors.sync="cardForm.cvc.errors"
              />
            </div>
          </div>
        </div>
      </el-form>
      <el-form ref="addressForm" :key="componentKey">
        <div class="mw-500 d-flex-column">
          <h3 class="mt-2 mb-0">Billing Address</h3>
          <button
            v-if="firstParticipantForm"
            @click.prevent="fillAddress(firstParticipantForm)"
            class="button-invisible green"
          >
            Fill address with
            {{ firstParticipantForm.addressLine1 }},
            {{ firstParticipantForm.addressLine2 }}
            {{ firstParticipantForm.suburb }}
          </button>
          <button
            v-if="teamAddressForm"
            @click.prevent="fillAddress(teamAddressForm)"
            class="button-invisible green"
          >
            Fill address with
            {{ teamAddressForm.addressLine1 }},
            {{ teamAddressForm.addressLine2 }}
            {{ teamAddressForm.suburb }}
          </button>
          <SuperInput
            :field="addressForm.addressLine1"
            v-model="addressForm.addressLine1.value"
            :errors.sync="addressForm.addressLine1.errors"
          />
          <SuperInput
            :field="addressForm.addressLine2"
            v-model="addressForm.addressLine2.value"
            :errors.sync="addressForm.addressLine2.errors"
          />
          <SuperInput
            :field="addressForm.suburb"
            v-model="addressForm.suburb.value"
            :errors.sync="addressForm.suburb.errors"
          />
          <SuperInput
            :field="addressForm.state"
            v-model="addressForm.state.value"
            :errors.sync="addressForm.state.errors"
          />
          <SuperInput
            :field="addressForm.postcode"
            v-model="addressForm.postcode.value"
            :errors.sync="addressForm.postcode.errors"
          />
        </div>
      </el-form>
      <div class="d-flex-column main mt-2 mw-400">
        <div>
          <el-button :disabled="disabled" type="primary" class="full-width" @click="submit"
            >Pay</el-button
          >
        </div>
        <div>
          <el-button @click="cancel" class="full-width button-invisible" type="success">
            Cancel
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import { errorOrPass, makeForm, flatten, populate } from "@/utils/forms";
import { formatMoney } from "@/utils/money";
import msg from "@/utils/constants/msg";
import { totalCheckout } from "@/utils/registration/pricing";
import { mapPricing } from "@/utils/registrationTeams";
import * as Pin from "../../utils/registration/pin";
export default {
  name: "Checkout",
  components: {
    SuperInput,
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    //  this.$nextTick(() => {
    if (Card)
      this.card = new Card({
        form: "form",
        container: ".card-container",
        debug: true,
      });
    // });
  },
  data() {
    return {
      disabled: false,
      cardForm: makeForm({
        number: {
          name: "number",
          label: "Card Number",
        },
        name: {
          name: "name",
          label: "Full Name",
        },
        expiry: {
          name: "expiry",
          label: "Expiry",
        },
        cvc: {
          name: "cvc",
          label: "CVC",
        },
      }),
      addressForm: makeForm({
        addressLine1: { label: "Address Line 1" },
        addressLine2: {
          label: "Address Line 2 (optional)",
          required: false,
        },
        suburb: { label: "Suburb" },
        state: { label: "State" },
        postcode: { label: "Postcode" },
      }),
      card: undefined,
      pinApi: undefined,
      componentKey: 1,
    };
  },
  computed: {
    ...mapGetters("registration", ["completed", "finishedRegoList"]),
    ...mapGetters("root", ["pinPk", "pinEnv"]),
    checkoutTotal() {
      if (this.completed && this.completed.length > 0) {
        return totalCheckout(this.completed);
      }
      const teamCurrent = this.$store.getters["registrationTeams/current"];
      let total = teamCurrent.price ? teamCurrent.price.pricing.competition.price : 0;

      if (
        teamCurrent.price &&
        teamCurrent.price.pricing.competition.discount &&
        teamCurrent.price.pricing.competition.discount.calculatedAmount
      ) {
        total -= teamCurrent.price.pricing.competition.discount.calculatedAmount;
      }
      total = Math.max(0, total);

      this.notEmptyAddons.forEach((d) => {
        total += d.price * (d.qty || 0);
      });

      return total;
    },
    notEmptyAddons() {
      const result = [];

      const teamCurrent = this.$store.getters["registrationTeams/current"];

      if (
        teamCurrent.price &&
        teamCurrent.price.pricing.competition.saleableItems &&
        teamCurrent.price.pricing.competition.saleableItems.length > 0
      ) {
        teamCurrent.price.pricing.competition.saleableItems.forEach((item) => {
          const { qty } = item;
          result.push({
            ...item,
            qty: qty || 0,
          });
        });
      }

      return result.filter((c) => c.qty && c.qty !== 0);
    },
    firstParticipantForm() {
      return this.completed && this.completed[0]
        ? this.completed[0].profile.participantForm
        : undefined;
    },
    teamAddressForm() {
      const teamCurrent = this.$store.getters["registrationTeams/current"];
      return teamCurrent && teamCurrent.teamDetails;
    },
  },
  methods: {
    ...mapMutations("registration", ["CLEAR_COMPLETED"]),
    ...mapActions("views", ["triggerEvent"]),
    submit() {
      try {
        this.disabled = true;
        this.$store.commit("root/LOADING", true);
        if (!this.pinApi) this.pinApi = new Pin.Api(this.pinPk, this.pinEnv);
        this.triggerEvent();
        if (errorOrPass(this.cardForm, null, this) && errorOrPass(this.addressForm, null, this)) {
          const card = flatten(this.cardForm);
          const address = flatten(this.addressForm);
          const expiry = card.expiry.replace(/\s/g, "").split("/");

          if (expiry.length !== 2 || expiry[1].length !== 2) {
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg: msg.error.invalidExpiry,
              type: "warning",
            });
            window.scrollTo(0, 0);
            return;
          }

          const handleSuccess = (response) => {
            // Block International Card === "american_express"
            if (response.scheme !== "master" && response.scheme !== "visa") {
              this.$store.commit("root/LOADING", false);
              this.$store.commit("views/PUSH_NOTIFICATION", {
                msg: msg.error.paymentNotSupported,
                type: "warning",
              });
              window.scrollTo(0, 0);
            } else if (response.token) {
              if (this.completed && this.completed.length > 0) {
                this.$http
                  .post("/nrl/api/v1/portal/registration/register", {
                    registrations: this.finishedRegoList,
                    paymentTotal: this.checkoutTotal,
                    cardToken: response.token,
                    paid: true,
                  })
                  .then(() => {
                    this.CLEAR_COMPLETED();
                    this.$store.commit("root/LOADING", false);
                    this.disabled = false;
                    this.$router.push({ name: "paymentcomplete" });
                  })
                  .catch((error) => {
                    this.$store.commit("root/LOADING", false);
                    this.disabled = false;
                    this.$store.commit("views/PUSH_NOTIFICATION", {
                      msg:
                        error.response && error.response.data && error.response.data.message
                          ? error.response.data.message
                          : msg.error.apiError,
                      type: "warning",
                    });
                    window.scrollTo(0, 0);
                  });
              } else {
                const teamCurrent = this.$store.getters["registrationTeams/current"];
                const mappedPricing = mapPricing(teamCurrent.price.pricing);
                this.$http
                  .post("/nrl/api/v1/portal/registration-team/register-team", {
                    answers: teamCurrent.answers,
                    competitionId: teamCurrent.entity._id,
                    ...teamCurrent.teamDetails,
                    pricing: mappedPricing,
                    paid: true,
                    paymentTotal: this.checkoutTotal,
                    cardToken: response.token,
                  })
                  .then((registrationResponse) => {
                    const { teamId } = registrationResponse.data.data;
                    this.$store.dispatch("registrationTeams/clearCurrent");
                    this.$store.commit("root/LOADING", false);
                    this.disabled = false;
                    if (teamId) {
                      this.$router
                        .push({
                          name: "invitemembers",
                          query: { teamid: teamId, registered: "true" },
                        })
                        .catch(() => {});
                    } else {
                      this.$router.push({ name: "paymentcomplete" }).catch(() => {});
                    }
                  })
                  .catch((error) => {
                    this.$store.commit("root/LOADING", false);
                    this.disabled = false;
                    this.$store.commit("views/PUSH_NOTIFICATION", {
                      msg:
                        error.response && error.response.data && error.response.data.message
                          ? error.response.data.message
                          : msg.error.apiError,
                      type: "warning",
                    });
                    window.scrollTo(0, 0);
                  });
              }
            } else {
              this.$store.commit("root/LOADING", false);
              this.disabled = false;
              this.$store.commit("views/PUSH_NOTIFICATION", {
                msg: msg.error.apiError,
                type: "warning",
              });
              window.scrollTo(0, 0);
            }
          };

          const handleError = (response) => {
            this.$store.commit("root/LOADING", false);
            window.scrollTo(0, 0);
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg:
                response.messages && response.messages.length > 0
                  ? response.messages[0].message
                  : response.error_description,
              type: "warning",
            });
          };
          // this.$httpcard
          //   .post("/1/cards", {
          //     publishable_api_key: process.env.VUE_APP_PIN_PK,
          //     number: card.number,
          //     name: card.name,
          //     expiry_month: expiry[0],
          //     expiry_year: expiry[1],
          //     cvc: card.cvc,
          //     address_line1: address.addressLine1,
          //     address_line2: address.addressLine2 || "",
          //     address_city: address.suburb,
          //     address_state: address.state,
          //     address_postcode: address.postcode,
          //     address_country: "Australia",
          //   })
          //   .then((response) => {
          //     handleSuccess(response);
          //   })
          //   .catch((error) => {
          //     handleError(error);
          //   });
          const pinCard = {
            number: card.number,
            name: card.name,
            expiry_month: expiry[0],
            expiry_year: expiry[1],
            cvc: card.cvc,
            address_line1: address.addressLine1,
            address_line2: address.addressLine2 || "",
            address_city: address.suburb,
            address_state: address.state,
            address_postcode: address.postcode,
            address_country: "Australia",
          };

          this.pinApi.createCardToken(pinCard).then(handleSuccess, handleError).done();
        } else {
          this.$store.commit("root/LOADING", false);
          window.scrollTo(0, 0);
        }
      } catch (e) {
        console.error({ e });
        this.$store.commit("root/LOADING", false);
        this.disabled = false;
      }
    },
    fillAddress(address) {
      this.addressForm = populate(this.addressForm, address);
      this.componentKey += 1;
    },
    cancel() {
      if (this.completed && this.completed.length > 0) {
        this.$router.push({ name: "payment" }).catch(() => {});
      } else {
        this.$router.push({ name: "teampayment" }).catch(() => {});
      }
    },
    formatMoney,
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
}

.green {
  color: $secondary;
  text-align: left;
  padding: 5px 0px;
  font-size: 0.75rem;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: $rl-highlight;
  }
}

.card-container {
  @media (max-width: $md) {
    ::v-deep .jp-card {
      transform: scale(0.7);
    }
    ::v-deep .jp-card.jp-card-flipped {
      transform: rotateY(180deg) scale(0.7) !important;
    }
  }

  ::v-deep .jp-card-front::before {
    background-image: none !important;
  }

  ::v-deep .jp-card-back::before {
    background-image: none !important;
  }
}
</style>
